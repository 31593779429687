import { nanoid } from 'nanoid';

export const headerData = {
  id: nanoid(),
  img: 'logo.png',
  facebookUrl: 'https://www.facebook.com/Massage.Lucia/',
  instagramUrl: 'https://instagram.com/massage_lucia_rodrigues?igshid=xte0cecjjr6i',
};

// HEAD DATA
export const headData = {
  title: 'Lucia RODRIGUES - Massages',
  lang: 'fr',
  description: 'Bienvenue sur le site de Lucia RODRIGUES - Massages',
};

// HERO DATA
export const heroData = {
  title: 'Un emploi du temps\n surchargé ?',
  subtitle: 'Vous vous sentez fatiguée ?\nsurmenée ?',
  paragraphOne: 'Prenez soin de vous le temps d’une pause détente, vous l’avez bien mérité.',
  paragraphTwo:
    'Ensemble, faisons un bilan pour vous mener à un bien-être physique et mental par le massage.',
  paragraphThree:
    'Se sentir bien dans son corps aide à acquérir la sérénité nécessaire pour être en accord avec soi et les autres.',
  img: 'hero.jpeg',
};

// ABOUT DATA
export const aboutData = {
  img: 'me.jpg',
  paragraphOne:
    "Franco- Brésilienne. J'ai grandi au Brésil, dans le contexte culturel de l’esthétique, où le bien-être et la beauté sont des valeurs très importantes.",
  paragraphTwo:
    'Au Brésil, les formations que j’ai suivies dans le bien-être m’ont permis d’acquérir de la technique. J’ai ensuite poursuivi mes formations dans mon pays d’adoption, la\n' +
    '\n France, et je peux ainsi proposer un massage professionnel sur mesure.',
  paragraphThree:
    'Pourquoi le massage ?\n' +
    '\n' +
    'Parce qu’il permet, par la technique utilisée, de soulager le corps\n' +
    '\n' +
    'et d’apaiser les tensions émotionnelles et physiques,et ainsi de\n' +
    '\n' +
    'procurer un bien-être instantané.\n' +
    '\n' +
    'Cela me passionne, et je ne cesse de me perfectionner ',
  paragraphFour:
    'Je vous propose d’en discuter ensemble afin de convenir du soin le plus approprié à vos besoins.',
  phone: '07 82 59 12 09',
  btn: 'Me contacter',
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'massage-bien-etre.jpeg',
    title: 'Massage Bien-être',
    info:
      "Le massage soulage le corps des tensions, du stress et des petites douleurs musculaires. Contrairement au massage relaxation qui vise en priorité la détente au niveau psychologique, le massage bien être agit avant tout sur le plan physique. Il s'agit d'un toucher visant à lutter contre les phénomènes pouvant entraîner des gênes chez le patient.",
    info2: '',
    url: '/services/#wellnessMassages',
  },
  {
    id: nanoid(),
    img: 'drainage-lymphatique.jpeg',
    title: 'Drainage Lymphatique',
    info:
      'Le drainage lymphatique manuel est une technique douce rythmée.  Cette méthode naturelle est d’une efficacité absolue  sur la stimulation de la circulation lymphatique et pour drainer les toxines, car le système lymphatique peut parfois être paresseux.',
    info2: '',
    url: '/services/#lymphaticDrainage',
  },
  {
    id: nanoid(),
    img: 'facial-japonais.jpeg',
    title: 'Massage Facial Japonais',
    info:
      'Au Japon la beauté est le stade ultime de la santé. Si le massage facial a une fonction esthétique, il s’adresse également aux personnes stressées qui cherchent à se détendre, car  il ne se contente pas de tonifier les muscles, mais   il stimule également l’ensemble de la circulation énergétique.',
    info2: '',
    url: '/services/#japaneseFacialMassage',
  },
];

// CONTACT DATA
export const contactData = {
  title: 'Prestations uniquement sur rendez-vous, du lundi au vendredi de 9h à 18h',
  locations: ['Lyon 3e Arrondissement'],
  email: 'contact@lucia.rodrigues.com',
  phone: '07 82 59 12 09',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'facebook',
      url: 'https://www.facebook.com/Massage.Lucia/',
    },
    {
      id: nanoid(),
      name: 'instagram',
      url: 'https://instagram.com/massage_lucia_rodrigues?igshid=xte0cecjjr6i',
    },
  ],
};

export const wellnessMassagesData = {
  title: "Massages bien-être réalisés à l'huile essentielle BIO",
  massages: [
    {
      id: nanoid(),
      title: 'Massage signature',
      description:
        'Massage du corps aux manœuvres relaxantes. Il s’adapte à votre humeur et  besoins du moment. Ensemble, déterminons la durée, la pression et les zones à privilégier pour vous soulager au mieux.',
      image: 'massage-signature.jpeg',
      duration: '50 minutes',
      price: '80 €',
    },
    {
      id: nanoid(),
      title: 'Massage Californien',
      description:
        'Manoeuvres fluides, enveloppantes et appuyées  qui vous aideront  au relâchement des tensions  musculaires, et vous apporteront une relaxation profonde.',
      image: 'massage-visage.jpeg',
      duration: '60 minutes',
      price: '65 €',
    },
  ],
};

export const japaneseFacialMassageData = {
  title: 'Massage facial',
  massages: [
    {
      id: nanoid(),
      title: 'Le Massage Facial Japonais',
      description:
        'Le massage Facial  Japonais (Kobido) est un art traditionnel au Japon.\n' +
        '\n' +
        'Au Japon la beauté est le stade ultime de la santé. Si le massage facial a une fonction esthétique, il s’adresse également aux personnes stressées qui cherchent à se détendre, car  il ne se contente pas de tonifier les muscles, mais   il stimule également l’ensemble de la circulation énergétique.\n' +
        '\n' +
        ' On travaille sur le côté gauche, le Yang, relié aux organes, et sur le côté droit, le Yin, relié aux viscères, pour mieux équilibrer le ki ou énergie vitale.\n' +
        '\n' +
        'Une séance  rafraîchit les traits,  donne de l’éclat à la peau, alors que la pratique régulière ralentit le vieillissement de la peau.',
      details: [
        'Redessine  l’ovale du visage',
        'Stimule la circulation sanguine et lymphatique',
        'Assouplit et augmente l’élasticité de la  peau',
        'Tonifie les muscles du visage',
        'Réoxygène l’épiderme',
        'Diminue les  rides, poches et cernes',
        'Améliore le sommeil',
        'Apporte une détente et une profonde relaxation',
      ],
      image: 'facial-japonais.jpeg',
      duration: '1 heure',
      price: '75 €',
    },
  ],
};

export const lymphaticDrainageData = {
  title: 'Le drainage lymphatique',
  massages: [
    {
      id: nanoid(),
      title: 'Méthode VODDER',
      description:
        'Le drainage lymphatique manuel est une technique douce rythmée. Cette méthode naturelle est d’une efficacité absolue sur la stimulation de la circulation lymphatique et pour drainer les toxines, car le système lymphatique peut parfois être paresseux. Ce drainage a pour fonctions de : ',
      details: [
        'Stimuler le système immunitaire',
        'Équilibrer le système nerveux ',
        'Détoxifier les tissus',
        'Rendre la peau plus claire, plus lumineuse',
        'Prévenir les vergetures',
        'Diminuer la rétention d’eau et la cellulite,',
        'Réduire la sensation de jambes lourdes',
        'Générer des effets sédatifs et anti-stress',
      ],
      image: 'drainage-lymphatique.jpeg',
      duration: '1 heure 15 minutes',
      price: '75 €',
      other: 'Protocole jambes lourdes',
      otherDuration: '1 heure',
      otherTarif: '70 €',
    },
  ],
};

export const shiatsuData = {
  title: 'Le Shiatsu',
  massages: [
    {
      id: nanoid(),
      title: 'Le Shiatsu, qu\'est-ce que c\'est ?',
      description:
        'C\'est un massage complet du corps originaire du Japon qui permet de : ',
      details: [
        'Rééquilibrer l\'énergie vitale du corps (en excès ou à défaut) afin d\'éliminer des tensions et éviter des symptômes physiques et/ou psychiques',
        'Réduire, voire stopper les douleurs, le stress, l\'anxiété',
        'Détendre et apaiser les muscles par des pressions et manipulations du corps',
        'Avoir un effet préventif en traitant les tensions du corps et de l\'esprit avant qu\'elles aient un effet néfaste',
        'Avoir un rééquilibrage de l\'énergie vitale du corps'
      ],
      description2: 'Le Shiatsu est donc une sorte de massage, qui associe autant le corporel que l\'émotionnel. Le bien-être de l\'un n\'allant pas sans l\'autre. Si on constates des troubles récurrents, fatigue, insomnie, problème de concentration ou de digestion, maux de tête ou de ventre, etc.',
      description3: 'Ton corps te parle ! Êtes-vous prête à l\'écouter ?',
      image: 'shiatsu.jpg',
      duration: '1 heure 15 minutes',
      price: '70 €',
      other: '',
      otherDuration: '',
      otherTarif: '',
    },
  ],
};
